<template>
  <section>
    <vs-card>
      <div slot="header">
        <vs-row vs-align="center">
          <vs-col vs-w="8">
            <h1>{{ lang.botMaker.catalogs.title[languageSelected] }}</h1>
          </vs-col>
        </vs-row>
      </div>

      <div class="create-edit-bot-wrapper">
        <KonaTabs :returnSelectedTab="true" @selectedTab="selectedTab">
          <KonaTab
            :name="lang.botMaker.catalogs.tabs.intents[languageSelected]"
            :selected="true"
            v-if="(isAuthAux('konecta.bot_maker.catalogs', false) || session.user.roles.canAccess.botMaker) && !fork"
          >
            <Intentions
              ref="intentions"
              :key="intentionKey"
              :hide-title="true"
              :fromCatalog="true"
              :read-only="isReadOnly"
            />
          </KonaTab>

          <KonaTab
            :name="lang.botMaker.catalogs.tabs.entities[languageSelected]"
          >
            <Entities :inBotSettings="false" :read-only="isReadOnly"/>
          </KonaTab>
        </KonaTabs>
      </div>
    </vs-card>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'Catalogs',
  mixins: [staffManagementMixin],
  data() {
    return {
      intentionKey: new Date().toString()
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    selectedTab(tableName) {
      if (this.$refs.intentions) this.$refs.intentions.resetSearch()
      //this.intentionKey = new Date().toString()
    }
  },
  components: {
    KonaTabs: () => import('../../../components/kona-tabs/KonaTabs.vue'),
    KonaTab: () => import('../../../components/kona-tabs/KonaTab.vue'),
    Intentions: () => import('../bot-maker/add-bot/Intentions.vue'),
    Entities: () => import('../entities/Entities.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    ...mapGetters('bots', ['bot']),
    fork() {
      return this.bot && this.bot.fork ? JSON.stringify(this.bot.fork) : null
    },
    isReadOnly() {
      return !this.isAuthAux('konecta.bot_maker.edit_bot', true)
    }
  }
}
</script>
